/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ReactSpeedometer from 'react-d3-speedometer';
import { isMobile } from 'react-device-detect';
import Fade from '@material-ui/core/Fade';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Helmet from 'react-helmet';
import {
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ResultsDisplay from '@fathomtech/trustalice-ui-components/dist/ImageWithText/ResultsDisplay/ResultsDisplay';
import ImageLeftSection from '@fathomtech/trustalice-ui-components/dist/ImageWithText/ImageLeft/ImageLeft';
import ImageRightSection from '@fathomtech/trustalice-ui-components/dist/ImageWithText/ImageRight/ImageRight';
import verifyMessage from '../../utils/api/Protection';
import createFeedback from '../../utils/api/Feedback';
import Layout from '../../layouts/Default';
import Loader from '../../components/Loader/Loader';
import backgroundImage from '../../../static/img/TrustAlice-Background.jpg';
import backgroundImage1 from '../../../static/img/TrustAlice-Background2.jpg';
import Section from '../../components/Section';
import illustration1 from '../../../static/img/TrustAlice Illustrations--02.svg';
import illustration2 from '../../../static/img/TrustAlice Illustrations--03.svg';
import illustration3 from '../../../static/img/TrustAlice Illustrations--07.svg';
import TAImage from '../../../static/img/Trust Alice Splash Screen.png';
import TAIcon from '../../../static/img/TrustAlice Icon.svg';
import speed0 from '../../../static/img/Meter SVGs/speedy0.svg';
import speed1 from '../../../static/img/Meter SVGs/speedy1.svg';
import speed2 from '../../../static/img/Meter SVGs/speedy2.svg';
import speed3 from '../../../static/img/Meter SVGs/speedy3.svg';
import speed4 from '../../../static/img/Meter SVGs/speedy4.svg';
import speed5 from '../../../static/img/Meter SVGs/speedy5.svg';
import speed6 from '../../../static/img/Meter SVGs/speedy6.svg';
import speed7 from '../../../static/img/Meter SVGs/speedy7.svg';
import speed8 from '../../../static/img/Meter SVGs/speedy8.svg';
import speed9 from '../../../static/img/Meter SVGs/speedy9.svg';
import speed10 from '../../../static/img/Meter SVGs/speedy10.svg';
import { fadeTexts } from '../../utils/constants';

const imageText1 = {
  title: 'Stop the fraudsters',
  // eslint-disable-next-line no-multi-str
  content: 'Trust Alice to scan messages for phishing and malware before you fall into their evil \
  traps and only answer calls from Trusted businesses.',
};
const imageText2 = {
  title: 'Verify the business',
  // eslint-disable-next-line no-multi-str
  content: 'Receive notifications from Alice before a legitimate business contacts you, \
  allowing you to be prepared for the communication and ready to verify it.',
};
const imageText3 = {
  title: 'Dedicated inbox',
  // eslint-disable-next-line no-multi-str
  content: 'Review your inbox of Trusted communications, even the ones you have missed or chose not to answer.',
};
const imageText4 = {
  title: 'Easy To Integrate',
  // eslint-disable-next-line no-multi-str
  content: 'Integrate TrustAlice into your existing systems and processes with one simple API call \
  then continue to communicate with your customers on a channel they prefer',
};
const imageText5 = {
  title: 'Customer Satisfaction',
  // eslint-disable-next-line no-multi-str
  content: 'Customers will now know which communications to trust, \
  leading to increased customer satisfaction and engagement levels along with an \
  enhanced digital relationship.',
};

export default function Personal() {
  const [color, setColor] = useState(false);
  const useStyles2 = makeStyles((theme) => ({
    layout: {
      [theme.breakpoints.up('md')]: {
        height: '82vh',
      },
      backgroundImage: `url(${backgroundImage})`,
      height: '100vh',
      width: '100vw',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    layoutNoImage: {
      height: '100vh',
      width: '100vw',
      backgroundColor: color,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    TAImage: {
      width: 300,
      // margin: '0px',
    },
    loadingDiv: {
      paddingTop: '25vh',
      // paddingRight: '30vw',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      // top: '50%',
      zIndex: 5000,
    },
    title: {
      [theme.breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: '1',
      },
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '1.1',
    },
    subtitle: {
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: '1.3',
      },
      fontWeight: 'normal',
      color: '#fff',
      fontSize: '18px',
      lineHeight: '1.5',
      marginBottom: '1.5rem',
      marginTop: '1.5rem',
    },
    section: {
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: '-10vh',
        paddingBottom: '15vh',
      },
    },
    sectionText: {
      textAlign: 'center',
      // marginBottom: theme.spacing(8),
      // maxWidth: 700,
      width: '100%',
      margin: '0px auto',
    },
    sectionTextTitle: {
      [theme.breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '1',
      },
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '1.2',
    },
    infoBlockTitle: {
      [theme.breakpoints.up('md')]: {
        fontSize: '44px',
        lineHeight: '56px',
        // marginTop: '3rem',
        // marginBottom: '3rem',
        maxWidth: 400,
        // paddingTop: '25vh',
      },
      fontWeight: 'bold',
      fontSize: '30px',
      lineHeight: '40px',
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    marginLeft: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 'auto',
      },
    },
    world: {
      [theme.breakpoints.up('md')]: {
        // maxHeight: '480px !important',
        height: '90vh !important',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    speedo: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    alicePhone: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        paddingBottom: '6vh',
        height: '100vh',
      },
      justifyContent: 'center',
      alignContent: 'center',
    },
    iconStyle: {
      // [theme.breakpoints.down('sm')]: {
      //   display: 'block',
      // },
      [theme.breakpoints.up('md')]: {
        backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        paddingTop: '3vh',
        paddingBottom: '3vh',
        // height: '20vh',
      },
      justifyContent: 'center',
      alignContent: 'center',
      height: '17vh',
    },
    gridTitleButton: {
      [theme.breakpoints.up('md')]: {
        paddingTop: '8vh',
        paddingBottom: '25vh',
        // paddingBottom: '10vh',
        // paddingLeft: '5vw',
      },
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    phoneButton: {
      // [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
      alignContent: 'center',
      // },
    },
    inputDemo: {
      [theme.breakpoints.down('sm')]: {
        width: '65vw',
      },
      width: '23vw',
    },
    box: {
      [theme.breakpoints.down('sm')]: {
        // width: '30vw',
        height: '100vh',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '30vw',
        maxHeight: '70vh',
        // maxHeight: '40vh',
        backgroundImage: `url(${backgroundImage})`,
      },
      wrap: 'nowrap',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flex: 1,
    },
    input: {
      backgroundColor: '#fff',
    },
    paddingTopSmallScreen: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: '10vh',
      },
    },
  }));
  const classes = useStyles2();
  const [message, setMessage] = useState('');
  const [results, setResults] = useState(false);
  const [formattedResults, setFormattedResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [fading, setFading] = useState(true);
  const [fadingText, setFadingText] = useState(fadeTexts[0]);

  const resetResults = () => {
    setResults(false);
    setMessage('');
    setFormattedResults(false);
    setColor('');
  };

  useEffect(() => {
    localStorage.setItem('lastPage', '/personal');
  }, []);

  const formatResults = async (response) => {
    const {
      trustLevel, trustDescription, trustColor, trustTitle,
    } = response;
    // console.log(trustLevel, trustDescription, trustColor, trustTitle);
    let selectedImg = speed1;
    setColor(trustColor || '#E35B48');

    switch (trustLevel) {
      case 0:
        selectedImg = speed0;
        break;
      case 1:
        selectedImg = speed1;
        break;
      case 2:
        selectedImg = speed2;
        break;
      case 3:
        selectedImg = speed3;
        break;
      case 4:
        selectedImg = speed4;
        break;
      case 5:
        selectedImg = speed5;
        break;
      case 6:
        selectedImg = speed6;
        break;
      case 7:
        selectedImg = speed7;
        break;
      case 8:
        selectedImg = speed8;
        break;
      case 9:
        selectedImg = speed9;
        break;
      case 10:
        selectedImg = speed10;
        break;
      default:
        selectedImg = speed10;
    }
    setFormattedResults({
      selectedImg,
      msgSubtitle: trustDescription,
      msgTitle: trustTitle || 'A score could not be calculated for technical reasons',
    });
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const timeoutMS = 1500;
  let pos = getRandomInt(fadeTexts.length);

  useEffect(() => {
    const interval = setInterval(() => {
      setFading((prev) => !prev);
    }, timeoutMS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadingText(fadeTexts[pos % fadeTexts.length]);
      pos += 1;
    }, 2 * timeoutMS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const handleScan = async () => {
    try {
      window.scrollTo(0, 0);
      setLoading(true);
      const response = await verifyMessage({
        message,
      });
      setResults(response);
      formatResults(response);
      window.scrollTo(0, 0);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const FadingText = () => (
    <div style={{ textAlign: 'center' }}>
      <Typography
        style={{
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '10vh',
          marginLeft: 'auto',
          paddingLeft: '10vw',
          paddingRight: '10vw',
        }}
        variant="h2"
        component="h2"
        className={classes.title}
      >
        {fadingText}
      </Typography>
    </div>
  );
  return (
    <Layout>
      <Helmet title={'Personal | Trust Alice'} />
      <div className={color ? classes.layoutNoImage : classes.layout}>
        {
          loading
            ? <div className={classes.loadingDiv}>
              <Loader />
                <Fade in={fading} timeout={ timeoutMS }
                >
                  {
                    <div>
                      <FadingText/>
                    </div>
                  }
              </Fade>
            </div>
            : <React.Fragment>
              {
                !formattedResults
                  ? <Section className={classes.section}>
                    <Grid
                      container
                      justify="center"
                      wrap='nowrap'
                    // alignItems="center"
                    >
                      <Grid item md={5} xs={12} className={`${classes.world}`}
                        style={{ justifyContent: 'center', alignContent: 'center' }}
                      >
                        <img src={TAImage} alt={'TrustAliceNoText'} className={classes.alicePhone} />
                      </Grid>
                      {/* <Grid item ={1}></Grid> */}
                      <Grid item md={7} xs={12} className={classes.gridTitleButton}>
                        <Box
                          className={classes.box}
                        >
                          <div className={classes.paddingTopSmallScreen}>
                            <img src={TAIcon} alt={'TrustAliceNoText'} className={classes.iconStyle} />
                          </div>
                          <div className={classes.phoneButton}>
                            <Typography
                              style={{ justifyContent: 'center', alignContent: 'center' }}
                              variant="h2"
                              component="h2"
                              className={classes.title}
                            >
                              Verify who&apos;s  contacting you
                            </Typography>
                          </div>
                          <div className={classes.phoneButton}>
                            <Typography
                              style={{
                                justifyContent: 'center', alignContent: 'center', fontColor: '#fff',
                              }}
                              className={classes.subtitle}
                              variant="h4"
                              component="h4"
                            >
                              Received a message that looks suspicious? Alice scans messages for fraud.
                            </Typography>
                          </div>
                          <div>
                            <TextField
                              className={classes.inputDemo}
                              multiline
                              variant='outlined'
                              color='#fff'
                              maxLines={5}
                              minLines={5}
                              rows={5}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              placeholder="Paste message or url here"
                              InputProps={{
                                className: classes.input,
                              }}
                            />
                          </div>
                          <div
                            className={classes.phoneButton}
                            style={{ padding: '2vh' }}
                          >
                            <Button
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                backgroundColor: '#295BA3',
                                color: '#FFFFFF',
                                width: '50%',
                              }}
                              onClick={(e) => {
                                // To stop the page reloading
                                e.preventDefault();
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'scan', {
                                  // string - required - The object that was interacted with (e.g.video)
                                    category: 'Scan',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'Scan',
                                  });
                                }
                                handleScan();
                              }}
                            color="primary" variant="contained">Scan</Button>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Section>
                  : <ResultsDisplay submitFb={(feedback) => {
                    createFeedback(feedback);
                  }
                  } message={message} formattedResults={formattedResults} resetResults={resetResults} results={results} />
              }
            </React.Fragment>
        }
      </div>
      <Section className={classes.section}>
        <div className={classes.sectionText}>
          <Typography
            style={{ color: '#295BA3', marginTop: '20vh' }}
            className={classes.sectionTextTitle}
            variant="h1"
            component="h1"
          >
            You can trust Alice to scan messages for fraud
          </Typography>
        </div>
      </Section>
      <ImageLeftSection
        data={imageText1}
        image={illustration1}
      />
      <ImageRightSection
        data={imageText2}
        image={illustration2}
      />
      <ImageLeftSection
        data={imageText3}
        image={illustration3}
      />
      {/* { loading
        && <div className={classes.loadingDiv}>
          <CircularProgress size={60} color="primary"/>
        </div>
      } */}
      {
        isComplete
        && <Alert
          onClose={() => { setIsComplete(false); }}
          severity="success"
          className={classes.alert}
        >
          Thank you, we are really excited to share Trustalice with you.
        </Alert>
      }
      {
        errorMessage
        && <Alert
          onClose={() => { setErrorMessage(''); }}
          severity="error"
          className={classes.alert}
        >
          An error has occured, please try again later.
        </Alert>
      }
    </Layout>
  );
}

Personal.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

// export const guidesPageQuery = graphql`
//   query GuidesQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;
