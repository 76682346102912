import { apiRequest } from '../index';
import config from '../../config';

const { API } = config;
const api = `${API}/feedback/`;
export default async function createFeedback(body) {
  try {
    const response = await apiRequest(`${api}`, 'POST', body);
    return response;
  } catch (error) {
    throw error;
  }
}
