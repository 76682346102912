import { apiRequest } from '../index';
import config from '../../config';

const { API } = config;
// console.log('API', API);

// const filterObjectErrors = (response) => {
//   console.log('RESP', response);
//   response.trustDescription.forEach((desc, index) => {
//     if (typeof desc === 'object') {
//       response.trustDescription[index] = 'ENOTFOUND';
//     }
//   });
//   // remove from individual url errors as well
//   // response.items.forEach((item) => {
//   //   const result = item.urlTrustDescription.filter(desc => typeof desc !== 'object');
//   //   item.urlTrustDescription = result;
//   // })
//   return response;
// };

const tempApi = `${API}/protection/`;

export default async function verifyMessage(body) {
  try {
    const response = await apiRequest(`${tempApi}`, 'POST', body);
    // const formattedResponse = filterObjectErrors(response);
    return response;
  } catch (error) {
    throw error;
  }
}
