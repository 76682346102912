// eslint-disable-next-line import/prefer-default-export
export const fadeTexts = [
  'Validating SSL Cert',
  'Checking Domain',
  'Checking for Phishing links',
  'Checking known Blocklists',
  'Looking up Registered companies',
  'Checking all Redirects',
  'Checking Traffic volumes',
  'Checking Logos',
  'Checking for HTTPS protocol',
  'Checking for Broken links',
  'Checking Browser attack vectors',
  'Checking for Multiple links',
];
